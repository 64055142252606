/**
 * Blog logo animation class
 */

import { gsap } from "gsap";
// For custom ease:
// import CustomEase from "gsap/src/CustomEase.js";

export class BlogLogoAnimation {
    /**
     * Initializes a new instance of the BlogLogoAnimation class, setting up the required selectors and animation timeline.
     *
     * @param {string} logoContainer A CSS selector for the menu item element that triggers the animation when hovered over.
     * @param {string} rectangle A CSS selector for the SVG rectangle element to be animated.
     * @param {string} circle A CSS selector for the SVG circle element to be animated.
     */
    constructor(logoContainer, rectangle, circle) {

        this.logoContainer = document.querySelector(logoContainer);
        this.rectangle = document.querySelector(rectangle);
        this.circle = document.querySelector(circle);

        this.timeline = gsap.timeline({ paused: true });

        this.setupAnimation();
    }

    /**
     * This method sets up the GSAP animation timeline for the SVG elements,
     * animates the rotation, stroke color, and stroke width of the SVG rectangle,
     * animates the radius of the SVG circle and attaches event listeners to the
     * menu item element to trigger the animation on hover and reverse it on
     * mouse leave.
     */
    setupAnimation() {
        const { rectangle, circle, timeline } = this;

        // Custom easing
        const customEase = gsap.parseEase("cubic-bezier(0.34, 0, 0.28, 1)");

        // Set the transform-origin to the center for circle and rectangle
        gsap.set(circle, { transformOrigin: "center center" });
        gsap.set(rectangle, { transformOrigin: "center center" });

        timeline
            .add(
                gsap.to(rectangle, {
                    rotation: 45,
                    stroke: "#00e49a",
                    duration: 0.6,
                    ease: customEase
                }),
                0 // Set the position parameter to 0 to start at the same time as the previous animation
            )
            .add(
                gsap.to(circle, {
                    attr: { r: 6 },
                    duration: 0.1,
                    ease: customEase
                }),
                0 // Set the position parameter to 0 to start at the same time as the previous animation
            );

        // Set a 1-second delay before playing the animation,
        // then call it once, when the page is full loaded
        timeline.delay(1).play();

        /* When hover
        this.logoContainer.addEventListener("mouseenter", () => {
            timeline.play();
        });

        this.logoContainer.addEventListener("mouseleave", () => {
            timeline.reverse();
        });
        */
    }
}
