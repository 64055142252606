/**
 * Main JavaScript file
 *
 * @package RootPixel
 * @subpackage Daytona
 * @since Daytona 1.0.0
 */

import "../scss/index.scss";

import { BlogLogoAnimation } from "./animations/BlogLogoAnimation.js";

document.addEventListener("DOMContentLoaded", () => {

    // Get the blog logo container
    const blogLogoContainer = document.querySelector(".js-blog-icon-container");

    // If the blog logo container exists, create an instance of the BlogLogoAnimation class
    if (blogLogoContainer) {
        const blogLogoAnimation = new BlogLogoAnimation(".js-blog-icon-container", ".blog-homepage-logo__rectangle", ".blog-homepage-logo__circle");
    }
});